<template>
    <v-row align="center" justify="center">
        <v-col cols="12" md="12" sm="12" class="mb-0">
            <v-breadcrumbs :items="pestanias" class="m-0">
                <template v-slot:divider>
                    <v-icon>fas fa-chevron-right</v-icon>
                </template>
            </v-breadcrumbs>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="d-flex flex-wrap justify-space-between mt-0">
            <v-col cols="3" sm="6" md="6" lg="2">
                <BoxCard color="teal accent-4" title="Ventas" subtitle="Total ventas"
                    icon="fas fa-chart-line" :value="entradaDetalle.totalSalida">
                </BoxCard>
            </v-col>
            <v-col cols="3" sm="6" md="6" lg="2">
                <BoxCard color="red darken-2" title="Mermas" subtitle="Total mermas"
                    icon="fas fa-arrow-down" :value="entradaDetalle.merma">
                </BoxCard>
            </v-col>
            <v-col cols="3" sm="6" md="6" lg="3">
                <BoxCard color="red darken-2" title="Cancelaciones" subtitle="Total cancelaciones"
                    icon="fas fa-cancel" :value="entradaDetalle.totalSalidaCancelado">
                </BoxCard>
            </v-col>
            <v-col cols="3" sm="6" md="6" lg="3">
                <BoxCard color="orange lighten-2" title="Traspaso" subtitle="Salida traspaso"
                    icon="fas fa-exchange-alt" :value="entradaDetalle.totalSalidaTraspaso">
                </BoxCard>
            </v-col>
            <v-col cols="3" sm="6" md="6" lg="2">
                <BoxCard color="teal accent-4" title="Existencias" subtitle="Existencias"
                    icon="fas fa-boxes" :value="entradaDetalle.existencia">
                </BoxCard>
            </v-col>
        </v-col>
        <v-col cols="12">
            
        </v-col>
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <SingleCalendar refElement="activeInicio" :input-date.sync="datesRange.start"
                        title="Fecha inicio" />
                    <SingleCalendar refElement="activeFin" :input-date.sync="datesRange.end" title="Fecha fin" />
                    <v-divider class="mx-2" inset vertical></v-divider>
                    <!-- <v-spacer></v-spacer> -->
                    <!-- <v-toolbar flat> -->
                    <!-- </v-toolbar> -->
                    <!-- <v-spacer></v-spacer> -->
                    <v-col cols="12" md="6" sm="12" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search"
                            label="Buscar registros por folio de ticket" single-inline hide-details
                            @keydown.enter="searchListaEntradaLote">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <v-tooltip left color="success">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="mt-4 ml-2" color="success" v-bind="attrs" v-on="on" dark
                                    @click="handleExcelExport">
                                    <v-icon dark left>fas fa-file-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Reporte general</span>
                        </v-tooltip>
                    </v-col>
                </v-card-title>
                <v-data-table :headers="headers" :items="listaEntradaLote" class="elevation-2" :loading="loading"
                    loading-text="Cargando... espera un momento" :options.sync="options" :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ],
                    showFirstLastPage: true,
                    firstIcon: 'fas fa-angle-double-left',
                    lastIcon: 'fas fa-angle-double-right',
                    prevIcon: 'fas fa-angle-left',
                    nextIcon: 'fas fa-angle-right'
                }" :server-items-length="totalListaEntradaLote">
                    <template v-slot:[`item.venta.ventaEstatus`]="{ item }">
                        <v-chip class="white--text text--lighten-1 text-h7" :color="item.venta?.colorEstatus ? item.venta.colorEstatus : 'teal accent-4'"
                            small dense filled label v-if="item.venta.ventaEstatus != null "> {{ item.venta.ventaEstatus }}
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import BoxCard from '@/components/shared/BoxCard.vue';
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import EntradaDetalleDataService from '@/service/entrada/EntradaDetalleDataService';
import VentaDetalleLoteDataService from '@/service/venta/VentaDetalleLoteDataService';
import { getFirstDayOfCurrentMonth, getToday } from '@/utils/DateUtil';
import { getParamsPagination } from '@/utils/pagination';
export default {
    name: "ListaEntradaDetalleLote",
    props: {
        entradaDetalleId: { type: Number, required: true }
    },
    created() {
        this.getEntradaDetalle(this.entradaDetalleId);
    },
    components: { SingleCalendar, BoxCard },
    data(){
        return {
            listaEntradaLote: [],
            entradaDetalle: {},
            datesRange: {
                start: getFirstDayOfCurrentMonth().toFormat('yyyy-MM-dd'),
                end: getToday().toFormat('yyyy-MM-dd')
            },
            options: {},
            totalListaEntradaLote: 0,
            loading: true,
            search: '',
            headers: [
                { text: 'Folio entrada', value: 'entradaDetalle.ticketFolio' },
                { text: 'Folio salida', value: 'venta.ticketFolio' },
                { text: 'Fecha salida', value: 'venta.registerDate' },
                { text: 'Tipo salida', value: 'venta.tipoSalida.nombre' },
                { text: 'Cantidad', value: 'cantidad' },
                { text: 'Kg', value: 'kg' },
                { text: 'Precio venta', value: 'precioVentaNatural' },
                { text: 'Unidad venta', value: 'ventaDetalle.producto.tipoUnidad.nombre' },
                { text: 'Total', value: 'totalNatural' },
                { text: 'Forma pago', value: 'venta.tipoPago.formaPago' },
                { text: 'Cliente', value: 'venta.cliente.razonSocial' },
                { text: 'Producto', value: 'ventaDetalle.producto.nombre' },
                { text: 'Estatus', value: 'venta.ventaEstatus', groupable: false, show: true }
                // { text: 'Total', value: 'venta.totalNatural' },
            ],
            pestanias: [
                { text: 'Inicio', disabled: false, to: '/controlPanel' },
                { text: 'Entradas', disabled: false, to: '/controlPanel/entradas' }
            ]
        }
    },
    methods: {
        getEntradaDetalle(entradaDetalleId){
            EntradaDetalleDataService.show(entradaDetalleId).then( resp => {
                this.entradaDetalle = resp.data;
                this.pestanias.push({ text: `Salidas de: ${this.entradaDetalle.ticketFolio} - ${this.entradaDetalle.producto.nombre} - ${this.entradaDetalle.cantidad}`, disabled: true });
            })
        },
        searchListaEntradaLote(){
            this.options.page = 1;
            this.initTableListaEntradaLote(this.options);
        },
        initTableListaEntradaLote(options = {}){
            this.loading = true;
            let params = getParamsPagination({ options, search: this.search,
                params: { startDate: this.datesRange.start, endDate: this.datesRange.end }
            });
            VentaDetalleLoteDataService.tableByEntradaDetalle(params, this.entradaDetalleId).then( resp => {
                const { rows, totalElements } = resp.data;
                this.listaEntradaLote = rows;
                this.totalListaEntradaLote = totalElements;
            }).catch( error => {
                this.$swal.fire({ icon: 'error', title: 'Falló la carga de información', html: error, showConfirmButton: false, timer: 4500 });
            }).finally( () => {
                this.loading = false;
            })
        },
        handleExcelExport(){
            this.$swal.fire({
                title: 'Quieres continuar con la descarga del reporte?',
                text: 'El tiempo de descarga puede tardar dependiendo de la cantidad de registros',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    const params = { entradaDetalleId : this.entradaDetalleId }
                    try {
                        return await VentaDetalleLoteDataService.downloadVentaDetalleLoteExcelByTipoSalida(params);
                    } catch (error) {
                        this.$swal.showValidationMessage(`Petición fallida: ${error}`);
                    }
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    if(result.value.status == 204){
                        this.$swal.fire({ icon: 'info', title: 'Reporte', text: 'No hay registros para descargar', showConfirmButton: false, timer: 1500 });
                    } else {
                        const url = window.URL.createObjectURL(new Blob([result.value.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `Salidas_de_la_entrada_${this.entradaDetalle.ticketFolio}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.$swal.fire({ icon: 'success', title: 'Reporte', text: 'Descarga completada', showConfirmButton: false, timer: 1500 });
                    }
                }
            })
        }
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableListaEntradaLote(val);
                }
            }, deep: true
        },
        'datesRange.start': function( val, oldVal ) {
            if (val !== oldVal) {
                this.initTableListaEntradaLote(this.options);
            }
        },
        'datesRange.end': function( val, oldVal ) {
            if (val !== oldVal) {
                this.initTableListaEntradaLote(this.options);
            }
        }
    }
}
</script>
<style>
.v-sheet {
    align-items: center;
    bottom: 0;
    justify-content: center;
    width: 100%;
}
</style>